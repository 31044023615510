import Splide from "@splidejs/splide";
export function initializeSplide() {
  document.addEventListener("DOMContentLoaded", function () {
    var splideReferenzenElement2 = document.querySelector(".splide.referenzen");

    if (splideReferenzenElement2) {
      var splideReferenzen = new Splide(splideReferenzenElement2, {
        type: "fade",
        // autoplay: true,
        drag: true,
        perPage: 1,
        perMove: 1,
        arrows: false,
        gap: "1rem",
        pagination: false,
        rewind: true,
      });
      splideReferenzen.mount();

      var forwardButton = document.querySelector(".splide-left");
      var backwardButton = document.querySelector(".splide-right");

      forwardButton.addEventListener("click", function () {
        splideReferenzen.go("<");
      });

      backwardButton.addEventListener("click", function () {
        splideReferenzen.go(">");
      });

      //watch when splide is moved
      splideReferenzen.on("move", function () {
        const counterReferenzen = splideReferenzenElement2.querySelectorAll(
          ".ref-number.counter-selector-animation"
        );

        counterReferenzen.forEach((counter) => {
          gsap.from(counter, {
            textContent: 0,
            duration: 1,
            snap: { textContent: 1 },
            scrollTrigger: {
              toggleActions: "restart none reverse none",
              trigger: counter,
              start: "top 80%",
              end: "bottom 100%"
            },
            onComplete: () => {
              counter.innerHTML = counter.innerHTML + "+";
            }
          });
        });
      });
    }
  });
}