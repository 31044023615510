import "./styles/main.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { initModals } from "./scripts/modals.js";
import { initializeSplide } from "./scripts/splide.js";
import { initLazyLoad } from "./scripts/lazyload.js";
import { initAnimations } from "./scripts/animations.js";

initializeSplide();

document.addEventListener("DOMContentLoaded", () => {
  initLazyLoad();
  initModals();
  initAnimations();
});

window.addEventListener("load", () => {
  AOS.refresh();
  setTimeout(() => {
    AOS.refresh();
  }, 1000);
});