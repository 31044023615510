export function initModals() {
    const maxLength = 300;
	const modal = document.getElementById("modal");
	const modalTitle = document.getElementById("modal-title");
	const modalText = document.getElementById("modal-text");
	const modalClose = document.getElementById("modal-close");
	if (modal && modalTitle && modalText && modalClose) {
		document.querySelectorAll(".testimonial-text").forEach((textElement) => {
			const fullText = textElement.getAttribute("data-full-text");
			const titleElement = textElement.closest('.testimonial-content').querySelector('.testimonial-title');

			if (!fullText) return;
			if (fullText.length > maxLength) {
				const truncatedText = fullText.slice(0, maxLength) + "... ";
				textElement.innerHTML = `
					${truncatedText}
					<a href="#" class="show-more font-medium underline">[weiter]</a>
				`;
			}

			const showMore = textElement.querySelector(".show-more");
			if (showMore) {
				showMore.addEventListener("click", (e) => {
					e.preventDefault();
					modalTitle.textContent = titleElement.textContent;
					modalText.textContent = fullText;
					modal.classList.remove("hidden-modal");
				});
			}
		});

		modalClose.addEventListener("click", () => {
			modal.classList.add("hidden-modal");
		});

		modal.addEventListener("click", (e) => {
			if (e.target === modal) {
				modal.classList.add("hidden-modal");
			}
		});
	}
}